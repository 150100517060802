<template>
  <el-dialog
    :visible.sync="visible"
    class="double-trans-ful-dialog"
    :title="`冷柜选中`"
    append-to-body
    v-loading="loading"
    fullscreen
  >
    <div class="table-wrap">
      <div class="list-wrap">
        <div class="title-wrap" style="margin-left: 10px">
          <el-form
            inline
            @keyup.enter.native.stop.prevent="getDataList()"
            @submit="() => false"
          >
            <el-form-item label="冷柜名称:">
              <el-input
                v-model="searchForm.goodsName"
                size="mini"
                placeholder="冷柜名称"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <el-button @click="getDataList()" type="primary">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-table
          :data="dataList"
          ref="dataList"
          border
          stripe
          @selection-change="selectionChangeHandle"
          @row-click="handleRowClick"
          v-loading="dataListLoading"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="冷柜名称"
          />
          <el-table-column
            prop="isUse"
            header-align="center"
            align="center"
            label="是否使用"
          />
          <!--          <el-table-column-->
          <!--            prop="qty"-->
          <!--            header-align="center"-->
          <!--            align="center"-->
          <!--            label="库存数量"-->
          <!--          />-->
        </el-table>
      </div>
      <div class="trans-wrap">
        <el-button @click="trans" icon="el-icon-arrow-right" type="primary" />
        <el-button type="danger" @click="clear" :disabled="!detailList.length"
          >清空所有已选商品</el-button
        >
      </div>
      <div class="list-wrap">
        <div class="title-wrap" style="line-height: 47px">已选商品</div>
        <el-table
          :data="detailList"
          ref="detailList"
          border
          stripe
          @selection-change="selectionChange"
        >
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="商品名称"
          />
          <el-table-column
            prop="isUse"
            header-align="center"
            align="center"
            label="是否使用"
          />
          <el-table-column header-align="center" align="center" label="操作">
            <template v-slot="scope">
              <i @click="handleDelete(scope.row)" class="el-icon-delete" />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="btn-wrap" slot="footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button @click="submit" type="primary" :disabled="!detailList.length"
        >确认</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { dialogMixin, doubleTransMixin } from '@/mixins';
export default {
  mixins: [dialogMixin, doubleTransMixin],
  data() {
    return {
      warehouseName: '',
      searchForm: {
        goodsName: '',
      },
      warehouseList: [],
    };
  },
  methods: {
    init(outWarehouseId, warehouseName) {
      this.$init({
        defaultMethod: () => {
          this.dataList = [];
          this.detailList = [];
          this.warehouseName = warehouseName;
          this.searchForm.goodsName = '';
          this.getDataList();
        },
      });
    },
    submit() {
      this.$submit({
        url: '/cms/wGoodsWarehouse/warehouseGoodsTransfers',
        data: {
          wgoodsWarehouseVOList: this.detailList.map((item) => {
            const { goodsId, count } = item;
            return { goodsId, qty: count };
          }),
        },
        after: () => {
          this.$emit('finish', null);
        },
      });
    },
    add(list) {
      const ids = this.detailList.map((item) => item.id);
      list.forEach((item) => {
        if (ids.includes(item.id))
          this.$error(`${item.name}已在【已选列表】中，请勿重复添加`);
        else this.detailList.push({ ...item, count: 1 });
      });
      this.$nextTick(() => {
        this.$refs.dataList.clearSelection();
        this.$forceUpdate();
      });
    },
    trans() {
      this.add(this.dataListSelections);
    },
    getDataList() {
      this.$http({
        url: `/cc/org/page`,
        method: 'get',
        params: {
          type: 0,
          isUse: 1,
          page: 1,
          limit: 1000,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataList = data.data.items;
        } else {
          this.dataList = [];
        }
      });
    },
    handleDelete(row) {
      this.$warning(`是否确认删除已选商品${row.goodsName}?`)
        .then(() => {
          this.detailList = this.detailList.filter(
            (item) => item.goodsId !== row.goodsId,
          );
        })
        .catch(() => {});
    },
    clear() {
      this.$warning(`是否确认清空已选列表?`)
        .then(() => {
          this.detailList = [];
        })
        .catch(() => {});
    },
    handleCountChange(item) {
      if (item.count > item.qty) {
        this.$error(
          `${item.goodsName}的库存为${item.qty},调拨数量不能大于库存数量。`,
        );
        item.count = item.qty;
      }
    },
  },
};
</script>

<style lang="scss">
.double-trans-ful-dialog {
  .el-dialog {
    display: flex;
    flex-direction: column;
    .el-dialog__header {
      border-bottom: 1px solid #f0f0f0;
    }
    .el-dialog__body {
      flex: 1;
      overflow: hidden;
      padding: 0;
      display: flex;
      flex-direction: column;
      .form-wrap {
        padding: 0 10px;
      }
      .table-wrap {
        flex: 0 0 100%;
        overflow: hidden;
        display: flex;
        position: relative;
        .list-wrap {
          width: 45%;
          display: flex;
          flex-direction: column;
          .title-wrap {
            height: 47px;
          }
          .el-table {
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .el-table__body-wrapper {
              flex: 1;
              overflow: hidden;
              z-index: 6;
              &:hover {
                overflow: overlay;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 5px #9b9ba3;
                background: rgba(152, 155, 163, 0.5);
              }
              &::-webkit-scrollbar {
                width: 7px;
                height: 7px;
              }
            }
          }
        }
        .trans-wrap {
          margin: 0 10px;
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .el-button {
            margin-bottom: 10px;
          }
        }
        .el-input-number {
          input {
            border: none;
          }
        }
      }
      .btn-wrap {
        text-align: right;
        padding: 10px;
        border-top: 1px solid #f0f0f0;
      }
    }
  }
  .el-button {
    padding: 7px 14px;
    border-radius: 0;
  }
  .el-form-item {
    margin-bottom: 10px;
    .el-form-item__label {
      width: 100px;
    }
    .el-form-item__content {
      height: 100%;
      .el-input {
        width: 200px;
      }
    }
  }
}
</style>
