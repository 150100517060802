<template>
  <div class="warehouse-page">
    <el-form
      :model="dataForm"
      @keyup.enter.native="getDataList(null, 1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item prop="periodId" class="typeInput" label="时段">
          <el-select
            v-model="dataForm.periodId"
            placeholder="时段"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="states" label="状态">
          <el-select
            v-model="dataForm.states"
            placeholder="状态"
            multiple
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.state"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDataList(null, 1)">查询</el-button>
          <el-button
            v-if="isAuth('tc:orgperiod:save')"
            type="primary"
            @click="$dialog('addOrUpdate')"
            >新增</el-button
          >
          <el-button
            v-if="isAuth('tc:orgperiod:delete')"
            type="danger"
            @click="deleteHandle()"
            :disabled="dataListSelections.length <= 0"
            >批量删除</el-button
          >
          <el-button
            type="danger"
            size="mini"
            v-if="isAuth('tc:orgperiod:changeState')"
            :disabled="dataListSelections.length <= 0"
            @click="changeState()"
            >终止经营时段</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div class="main-container">
      <div class="aside-wrap">
        <el-table
          height="calc(100vh - 210px)"
          :data="orgList"
          ref="orgList"
          border
          :row-class-name="tableRowClassName"
          @selection-change="$selectionChange($event, 'orgList')"
          @row-click="
            $clickRow(
              $event,
              'orgList',
              getDataList($event, 1),
              colorChange($event),
            )
          "
          @select="$select(arguments, 'orgList')"
          @select-all="$clearSelection('orgList')"
        >
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="供应商"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'orgList')"
          @current-change="$currentChange($event, 'orgList')"
          :current-page="orgListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="orgListSize"
          :total="orgListCount"
          layout="total, sizes, prev, pager, next"
        />
      </div>
      <div class="main-wrap">
        <el-table
          :data="dataList"
          border
          stripe
          ref="dataList"
          @select-all="handleSelectAll"
          @selection-change="selectionChangeHandle"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          >
          </el-table-column>
          <el-table-column
            prop="periodWithTimeName"
            header-align="center"
            align="center"
            label="时段"
          >
          </el-table-column>
          <el-table-column
            prop="orgPeriodDate"
            header-align="center"
            align="center"
            width="120"
            label="经营时段有效期"
          ></el-table-column>

          <el-table-column
            prop="rate"
            header-align="center"
            align="center"
            label="服务费率"
          >
          </el-table-column>

          <el-table-column
            prop="state"
            header-align="center"
            align="center"
            width="120"
            label="状态"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.state === 0" size="small">未生效</el-tag>
              <el-tag v-else-if="scope.row.state === 1" size="small"
                >生效中</el-tag
              >
              <el-tag v-else-if="scope.row.state === 2" size="small"
                >已失效</el-tag
              >
              <el-tag v-else-if="scope.row.state === 3" size="small"
                >已终止</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column
            prop="createdAt"
            header-align="center"
            align="center"
            label="创建日期"
          >
          </el-table-column>

          <el-table-column
            prop="createdBy"
            header-align="center"
            align="center"
            label="创建者"
          >
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            width="220"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                v-if="isAuth('tc:orgperiod:info')"
                type="text"
                size="small"
                @click="$dialog('addOrUpdate', scope.row.id, true)"
                >查看</el-button
              >
              <el-button
                v-if="isAuth('tc:orgperiod:update')"
                type="text"
                size="small"
                @click="
                  $dialog(
                    'addOrUpdate',
                    scope.row.id,
                    false,
                    scope.row.state === 1,
                  )
                "
                >修改</el-button
              >
              <el-button
                v-if="isAuth('tc:orgperiod:delete')"
                type="text"
                size="small"
                @click="deleteHandle(scope.row.id)"
                >删除</el-button
              >
              <el-button
                v-if="isAuth('tc:orgperiod:info')"
                type="text"
                size="small"
                @click="$dialog('orgPeriodLog', scope.row.id)"
                >查看日期记录</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <list-footer>
          <el-pagination
            slot="right"
            class="right"
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </list-footer>
      </div>
    </div>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDataList(null, 1)"
    />
    <!-- 分配冷柜 -->
    <freezer-trans
      v-if="freezerVisible"
      ref="freezer"
      @success="getDataList(null, 1)"
    />
    <org-period-log v-if="orgPeriodLogVisible" ref="orgPeriodLog" />
  </div>
</template>

<script>
import { threeListMixin, listMixin, normal } from '@/mixins';
import AddOrUpdate from './org-period-add-or-update';
import freezerTrans from './freezer-trans';
import orgPeriodLog from './org-period-log';
import { getPeriodList } from '@/utils/options.js';

export default {
  mixins: [threeListMixin, listMixin, normal],
  data() {
    return {
      dataForm: {
        orgId: '',
        periodId: '',
        states: [0, 1],
      },
      chooseIndex: 0,
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      orgListIndex: 1,
      orgListSize: 10,
      orgListCount: 0,
      dataListSelections: [],
      companyList: [],
      orgList: [],
      periodList: [],
      stateList: [
        { id: 0, state: '未生效' },
        { id: 1, state: '生效中' },
        { id: 2, state: '已失效' },
        { id: 3, state: '已终止' },
      ],
      addOrUpdateVisible: false,
      freezerVisible: false,
      orgPeriodLogVisible: false,
    };
  },
  components: {
    AddOrUpdate,
    freezerTrans,
    orgPeriodLog,
  },
  activated() {
    this.getOrgList();
  },
  created() {
    this.getPeriodList();
  },
  methods: {
    tableRowClassName({ row }) {
      // this.
      if (row.id === this.chooseIndex) {
        return 'danger-row';
      } else {
        return '';
      }
    },
    colorChange(row) {
      this.chooseIndex = row.id;
    },
    // 获取数据列表
    getDataList(row, page) {
      this.dataForm.orgId = row ? row.id : this.dataForm.orgId;
      this.pageIndex = page ? page : this.pageIndex;
      this.$api({
        url: '/tc/orgperiod/list',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          orgId: this.dataForm.orgId,
          periodId: this.dataForm.periodId,
          states: this.dataForm.states.join(','),
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.page.records;
            this.totalPage = data.page.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
        },
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否确认对这些经营时段进行删除操作?`,
        url: '/tc/orgperiod/delete',
        data: ids,
        after: () => {
          this.getDataList(null, 1);
        },
      });
    },
    changeState(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$handleDelete({
        tip: `是否终止这些经营时段?`,
        url: '/tc/orgperiod/changeState',
        data: ids,
        after: () => {
          this.getDataList(null, 1);
        },
      });
    },
    getOrgList() {
      this.$http({
        url: `/cc/org/page`,
        method: 'get',
        params: {
          type: 0,
          isUse: 1,
          page: this.orgListIndex,
          limit: this.orgListSize,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
          this.orgListCount = data.data.total;
          this.dataForm.orgId = data.data.items[0].id;
          this.getDataList(null, 1);
        } else {
          this.orgList = [];
          this.orgListCount = 0;
        }
      });
    },
    getPeriodList() {
      getPeriodList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
  },
};
</script>
