<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="供应商" prop="orgId">
          <el-select
            v-model="dataForm.orgId"
            :disabled="disabled || isValid"
            @change="changeOrg"
          >
            <el-option
              v-for="(item, index) in contractOrgList"
              :key="index"
              :label="item.orgName"
              :value="item.orgId"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="时段" prop="periodId">
          <el-select
            v-model="dataForm.periodId"
            :disabled="disabled || isValid"
          >
            <el-option
              v-for="(item, index) in periodList"
              :key="index"
              :label="item.withTimeName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="经营时段有效期" class="typeInput">
          <el-date-picker
            v-model="date"
            :disabled="disabled || isValid"
            :picker-options="datePicker"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="服务费率" prop="rate">
          <el-input-number
            v-model="dataForm.rate"
            :disabled="disabled"
            :precision="2"
            :min="0"
            :max="1"
            :step="0.01"
          >
          </el-input-number>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getPeriodList, getContractOrgList } from '@/utils/options.js';
export default {
  data() {
    return {
      disabled: false,
      isValid: false,
      visible: false,
      timePeriod: {
        maxDate: null,
        minDate: null,
      },
      maxDate: null,
      minDate: null,
      dataForm: {
        orgId: '',
        companyId: '',
        periodId: '',
        startDate: '',
        endDate: '',
      },
      date: [],
      contractOrgList: [],
      companyList: [],
      periodList: [],
      // datePicker: {
      //   disabledDate: this.picker(),
      // },
      dataRule: {
        orgId: [{ required: true, message: '供应商不能为空', trigger: 'blur' }],
        periodId: [
          { required: true, message: '时段不能为空', trigger: 'blur' },
        ],
        rate: [
          { required: true, message: '服务费率不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    datePicker() {
      let _this = this;
      return {
        disabledDate(time) {
          let beforeTime = new Date(_this.timePeriod.minDate).getTime();
          let afterTime = new Date(_this.timePeriod.maxDate).getTime();
          return time.getTime() > afterTime || time.getTime() < beforeTime;
        },
      };
    },
  },
  methods: {
    init(id, disabled, isValid) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.isValid = isValid;
      this.timePeriod.maxDate = null;
      this.timePeriod.minDate = null;
      this.getContractOrgList();
      this.getPeriodList();
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/orgperiod/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = { ...data.orgperiod };
              this.date = [this.dataForm.startDate, this.dataForm.endDate];
            }
          });
        } else {
          this.date = [];
        }
      });
    },
    changeOrg(event) {
      let org = this.contractOrgList.find((e) => (e.getOrgId = event));
      this.$set(this.timePeriod, 'maxDate', org.endDate);
      this.$set(this.timePeriod, 'minDate', org.startDate);
      // this.maxDate = org.endDate;
      // this.minDate = org.startDate;
      console.log(this.minDate);
      console.log(this.maxDate);
      this.date = [];
    },
    getContractOrgList() {
      getContractOrgList().then(({ data }) => {
        if (data && data.code === 0) {
          this.contractOrgList = data.list;
        }
      });
    },

    getPeriodList() {
      getPeriodList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.periodList = data.data.items;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.dataForm.startDate = this.date[0];
      this.dataForm.endDate = this.date[1];
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/orgperiod/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: {
              ...this.dataForm,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.code === 422) {
              this.$message({
                message: data.errors.periodId,
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
  },
};
</script>
